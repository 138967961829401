import { CreateListArgs, GetListResponse } from 'redux/reducers/api/prospects';
import { camelCaseKeys, extractDomainFromUrl, snakeCaseKeys } from 'utils/util';
import { ListBuilderState } from './listBuilderReducer';
import { ListType } from './types';
import { ListBuilderFormSchema } from './formSchema';
import {
  WizardListParamsOrganizationOptions,
  WizardListParamsPeopleOptions,
} from 'components/Wizard/WizardConfig';
import { ListEntityType } from 'adapters/types';
import { Option } from '@/components/Wizard/WizardConfig';
import isUUID from 'validator/lib/isUUID';
import { safeNormalizeUUID } from 'utils/uuid';

export function listTypeFromApi(listType: string): ListType {
  switch (listType) {
    case 'Prospects':
      return 'prospects';
    case 'Competitors':
      return 'competitors';
    case 'Customers':
      return 'customers';
    case 'Active pipeline':
      return 'pipeline';
    default:
      throw new Error(`Unknown list type: ${listType}`);
  }
}

export function includedPeopleFromApi(
  included_people?: WizardListParamsPeopleOptions,
  profilesMap?: Record<string, string>
): ListBuilderFormSchema['includedPeople'] {
  if (!included_people || !profilesMap) {
    return [];
  }
  if (!included_people.raw?.length && !included_people.uuids?.length) {
    return [];
  }

  const { raw, uuids } = included_people;

  return [
    ...uuids.map((id) => ({ value: id, label: profilesMap[id] })),
    ...raw.map(({ email }) => ({
      label: email,
      value: email,
      meta: { email },
    })),
  ];
}

export function includedOrganizationsFromApi(
  included_organizations?: WizardListParamsOrganizationOptions,
  organizationsMap?: Record<string, string>
): ListBuilderFormSchema['includedOrganizations'] {
  if (!included_organizations || !organizationsMap) {
    return [];
  }

  if (
    !included_organizations.raw?.length &&
    !included_organizations.uuids?.length
  ) {
    return [];
  }

  const { raw, uuids } = included_organizations;

  return [
    ...uuids.map((id) => ({
      value: id.toLocaleLowerCase(),
      label: organizationsMap[id.toLocaleLowerCase()],
    })),
    ...raw.map(({ domain }) => ({
      label: domain,
      value: domain,
      meta: { domain },
    })),
  ];
}

export function prepareListTypeForApi(listType: ListType): string {
  switch (listType) {
    case 'prospects':
      return 'Prospects';
    case 'competitors':
      return 'Competitors';
    case 'customers':
      return 'Customers';
    case 'pipeline':
      return 'Active pipeline';
    default:
      throw new Error(`Unknown list type: ${listType}`);
  }
}

export function preparePeopleOptionsForApi(
  people: Option[]
): WizardListParamsPeopleOptions {
  return people.reduce(
    (acc, item: Option) => {
      if (isUUID(item.value)) {
        acc.uuids.push(safeNormalizeUUID(item.value));
      } else if (item.meta?.uuid) {
        acc.uuids.push(safeNormalizeUUID(item.meta.uuid as string));
      } else if (item.meta?.email) {
        acc.raw.push({ email: item.meta.email as string });
      }

      return acc;
    },
    {
      uuids: [],
      raw: [],
    } as WizardListParamsPeopleOptions
  );
}

export function prepareOrganizationOptionsForApi(
  organizations: Option[]
): WizardListParamsOrganizationOptions {
  return organizations.reduce(
    (acc, item: Option) => {
      if (isUUID(item.value)) {
        acc.uuids.push(safeNormalizeUUID(item.value));
      } else if (item.meta?.uuid) {
        acc.uuids.push(safeNormalizeUUID(item.meta.uuid as string));
      } else if (item.meta?.domain) {
        acc.raw.push({
          domain: extractDomainFromUrl(item.meta.domain as string),
        });
      }

      return acc;
    },
    {
      uuids: [],
      raw: [],
    } as WizardListParamsOrganizationOptions
  );
}

export function prepareListForApi(
  formValues: ListBuilderFormSchema
): CreateListArgs {
  const listName = formValues.listName;
  const listType = prepareListTypeForApi(formValues.listType);
  const listEntityType =
    formValues.listEntityType === 'profiles'
      ? ListEntityType.Profiles
      : ListEntityType.Organizations;

  return {
    listName,
    listType,
    icpBuilder: true,
    listSource: '',
    listEntityType,
    icpParams: null,
    wizardParams: {
      ...snakeCaseKeys(formValues),
      result_type: listEntityType,
      average_deal_size: 0,
      list_type: listType,
      list_source: '',
      list_name: listName,
      list_entity_type: listEntityType,
      included_naics2022_codes: formValues.includedNaics2022Codes,
      included_people: preparePeopleOptionsForApi(formValues.includedPeople),
      included_organizations: prepareOrganizationOptionsForApi(
        formValues.includedOrganizations
      ),
      // @ts-ignore
      wizard_state: undefined,
    },
  };
}

export function reducerStateFromApi(list: GetListResponse): ListBuilderState {
  const {
    wizard_params: {
      included_organizations,
      included_people,
      included_job_titles,
      included_company_owners,
      included_contact_owners,
      included_deal_stages,
      included_deal_owners,
      included_naics2022_codes,
      included_employee_ranges,
      included_prospect_lists,
    },
    list_entity_type,
  } = list;

  const peopleInputMethod =
    list_entity_type === 'profiles'
      ? included_people?.raw?.length || included_people?.uuids?.length
        ? 'csv'
        : included_job_titles?.length
        ? 'jobTitles'
        : included_company_owners?.length || included_contact_owners?.length
        ? 'crm'
        : undefined
      : undefined;

  const organizationsInputMethod =
    list_entity_type === 'organizations'
      ? included_organizations?.raw.length ||
        included_organizations?.uuids.length
        ? 'csv'
        : included_naics2022_codes?.length || included_employee_ranges?.length
        ? 'describe'
        : included_deal_stages?.length ||
          included_deal_owners?.length ||
          included_company_owners?.length
        ? 'crm'
        : undefined
      : undefined;

  const peopleOrganizationsInputMethod =
    list_entity_type === 'profiles'
      ? included_organizations?.raw?.length ||
        included_organizations?.uuids?.length
        ? 'csv'
        : included_naics2022_codes?.length || included_employee_ranges?.length
        ? 'describe'
        : included_prospect_lists?.length
        ? 'list'
        : undefined
      : undefined;

  return {
    peopleInputMethod,
    organizationsInputMethod,
    peopleOrganizationsInputMethod,
  } as ListBuilderState;
}

export function formStateFromApi(
  list: GetListResponse,
  profilesMap: Record<string, string>,
  organizationsMap: Record<string, string>
): ListBuilderFormSchema {
  const { wizard_params, list_type, list_entity_type } = list;

  const { included_people, included_organizations } = wizard_params;

  return {
    ...camelCaseKeys(wizard_params),
    listType: listTypeFromApi(list_type),
    listEntityType: list_entity_type,
    includedPeople: includedPeopleFromApi(included_people, profilesMap),
    includedOrganizations: includedOrganizationsFromApi(
      included_organizations,
      organizationsMap
    ),
  } as ListBuilderFormSchema;
}

export function checkIsCrmList(
  formValues: ListBuilderFormSchema,
  state: ListBuilderState
): boolean {
  return (
    (formValues.listEntityType === 'profiles' &&
      state.peopleInputMethod === 'crm') ||
    (formValues.listEntityType === 'organizations' &&
      state.organizationsInputMethod === 'crm')
  );
}
